// Converted Variables

// Custom Media Query Variables

/*

   ASPECT RATIOS

*/

/* This is for fluid media that is embedded from third party sites like youtube, vimeo etc.
 * Wrap the outer element in aspect-ratio and then extend it with the desired ratio i.e
 * Make sure there are no height and width attributes on the embedded media.
 * Adapted from: https://github.com/suitcss/components-flex-embed
 *
 * Example:
 *
 * <div class="aspect-ratio aspect-ratio--16x9">
 *  <iframe class="aspect-ratio--object"></iframe>
 * </div>
 *
 * */

/* ASPECT RATIO BASIC */

.aspect-ratio {
  height: 0;
  position: relative;
}

.obj-cover {
  object-fit: cover;
}

.obj-contain {
  object-fit: contain;
}

.aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 100;
}

@media #{$breakpoint-small} {
  .aspect-ratio-s {
    height: 0;
    position: relative;
  }

  .aspect-ratio--object-s {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 100;
  }
  .obj-cover-s {
    object-fit: cover;
  }
  
  .obj-contain-s {
    object-fit: contain;
  }
}

@media #{$breakpoint-medium} {
  .aspect-ratio-m {
    height: 0;
    position: relative;
  }
  .aspect-ratio--object-m {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 100;
  }
  .obj-cover-m {
    object-fit: cover;
  }
  
  .obj-contain-m {
    object-fit: contain;
  }
}

@media #{$breakpoint-large} {
  .aspect-ratio-l {
    height: 0;
    position: relative;
  }
  .aspect-ratio--object-l {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 100;
  }
  .obj-cover-m {
    object-fit: cover;
  }
  
  .obj-contain-m {
    object-fit: contain;
  }
}

@media #{$breakpoint-verylarge} {
  .aspect-ratio-vl {
    height: 0;
    position: relative;
  }
  .aspect-ratio--object-vl {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 100;
  }
  .obj-cover-l {
    object-fit: cover;
  }
  
  .obj-contain-l {
    object-fit: contain;
  }
}

/* ASPECT RATIO DIMENSIONS */

/* ratio options */

$aspectratios: (
  "16x9": 56.25%,
  "9x16": 177.77%,
  "4x3": 75%,
  "3x2": 66.66%,
  "3x4": 133.33%,
  "6x4": 66.66%,
  "4x6": 150%,
  "8x5": 62.5%,
  "5x8": 160%,
  "7x5": 71.42%,
  "5x7": 140%,
  "2x1": 50%,
  "1x1": 100%
);

// default
@each $name, $aspectratio in $aspectratios {
  .aspect-ratio--#{$name} {
    padding-bottom: $aspectratio;
    .--padding-flex {
      margin-top: $aspectratio;
    }
  }
}

// small
@each $name, $aspectratio in $aspectratios {
  @media #{$breakpoint-small} {
    .aspect-ratio--#{$name}-s {
      padding-bottom: $aspectratio;
      .--padding-flex {
        margin-top: $aspectratio;
      }
    }
  }
}

// medium

@each $name, $aspectratio in $aspectratios {
  @media #{$breakpoint-medium} {
    .aspect-ratio--#{$name}-m {
      padding-bottom: $aspectratio;
      .--padding-flex {
        margin-top: $aspectratio;
      }
    }
  }
}

// large

@each $name, $aspectratio in $aspectratios {
  @media #{$breakpoint-large} {
    .aspect-ratio--#{$name}-l {
      padding-bottom: $aspectratio;
      .--padding-flex {
        margin-top: $aspectratio;
      }
    }
  }
}

// very large

@each $name, $aspectratio in $aspectratios {
  @media #{$breakpoint-verylarge} {
    .aspect-ratio--#{$name}-vl {
      padding-bottom: ($aspectratio);
      .--padding-flex {
        margin-top: ($aspectratio);
      }
    }
  }
}
