// Converted Variables

// Custom Media Query Variables

/*

   TEXT TRANSFORM
   Docs: http://tachyons.io/docs/typography/text-transform/

   Base:
     tt = text-transform

   Modifiers
     c = capitalize
     l = lowercase
     u = uppercase
     n = none

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.ttc {
  text-transform: capitalize;
}
.ttl {
  text-transform: lowercase;
}
.ttu {
  text-transform: uppercase;
}
.ttn {
  text-transform: none;
}

@media #{$breakpoint-small} {
  .ttc-s {
    text-transform: capitalize;
  }
  .ttl-s {
    text-transform: lowercase;
  }
  .ttu-s {
    text-transform: uppercase;
  }
  .ttn-s {
    text-transform: none;
  }
}

@media #{$breakpoint-medium} {
  .ttc-m {
    text-transform: capitalize;
  }
  .ttl-m {
    text-transform: lowercase;
  }
  .ttu-m {
    text-transform: uppercase;
  }
  .ttn-m {
    text-transform: none;
  }
}

@media #{$breakpoint-large} {
  .ttc-l {
    text-transform: capitalize;
  }
  .ttl-l {
    text-transform: lowercase;
  }
  .ttu-l {
    text-transform: uppercase;
  }
  .ttn-l {
    text-transform: none;
  }
}
