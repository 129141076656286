// Converted Variables

// Custom Media Query Variables

/*

   HEIGHTS
   Docs: http://tachyons.io/docs/layout/heights/

   Base:
     h = height
     min-h = min-height
     min-vh = min-height vertical screen height
     vh = vertical screen height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale

     -25   = literal value 25%
     -50   = literal value 50%
     -75   = literal value 75%
     -100  = literal value 100%

     -auto = string value of auto
     -inherit = string value of inherit

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

$heights: (
  "0": 0%,
  "10": 10%,
  "20": 20%,
  "25": 25%,
  "30": 30%,
  "40": 40%,
  "50": 50%,
  "60": 60%,
  "70": 70%,
  "75": 75%,
  "80": 80%,
  "90": 90%,
  "100": 100%,
  "10px": 10px,
  "20px": 20px,
  "30px": 30px,
  "40px": 40px,
  "50px": 50px,
  "60px": 60px,
  "70px": 70px,
  "80px": 80px,
  "90px": 90px,
  "100px": 100px,
  "auto": auto,
  "inherit": inherit
);

$heightsRem: (
  "1": $height-1,
  "2": $height-2,
  "3": $height-3,
  "4": $height-4,
  "5": $height-5,
  "6": $height-6,
  "10": 10rem,
  "11": 11rem,
  "12": 12rem,
  "13": 13rem,
  "14": 14rem,
  "15": 15rem,
  "16": 16rem,
  "17": 17rem,
  "18": 18rem,
  "19": 19rem,
  "20": 20rem
);

$verticalHeights: (
  "25": 25vh,
  "50": 50vh,
  "70": 70vh,
  "75": 75vh,
  "80": 80vh,
  "90": 90vh,
  "100": 100vh
);

// 📏 percent and pixels

@each $name, $height in $heights {
  .h-#{$name} {
    height: $height;
  }
}

// 📏 rems

@each $name, $heightRem in $heightsRem {
  .h#{$name} {
    height: $heightRem;
  }
}

// 📏 vertical heights

@each $name, $verticalHeight in $verticalHeights {
  .vh-#{$name} {
    height: $verticalHeight;
  }
}

// SMALL BREAKPOINT

@media #{$breakpoint-small} {
  // 📏 percent and pixels

  @each $name, $height in $heights {
    .h-#{$name}-s {
      height: $height;
    }
  }

  // 📏 rems

  @each $name, $heightRem in $heightsRem {
    .h#{$name}-s {
      height: $heightRem;
    }
  }

  // 📏 vertical heights

  @each $name, $verticalHeight in $verticalHeights {
    .vh-#{$name}-s {
      height: $verticalHeight;
    }
  }
}

// MEDIUM BREAKPOINT

@media #{$breakpoint-medium} {
  // percent and pixels
  @each $name, $height in $heights {
    .h-#{$name}-m {
      height: $height;
    }
  }

  // rems

  @each $name, $heightRem in $heightsRem {
    .h#{$name}-m {
      height: $heightRem;
    }
  }

  // vertical heights

  @each $name, $verticalHeight in $verticalHeights {
    .vh-#{$name}-m {
      height: $verticalHeight;
    }
  }
}

// LARGE BREAKPOINT

@media #{$breakpoint-large} {
  // percent and pixels
  @each $name, $height in $heights {
    .h-#{$name}-l {
      height: $height;
    }
  }

  // rems

  @each $name, $heightRem in $heightsRem {
    .h#{$name}-l {
      height: $heightRem;
    }
  }

  // vertical heights

  @each $name, $verticalHeight in $verticalHeights {
    .vh-#{$name}-l {
      height: $verticalHeight;
    }
  }
}

// /* Height Scale */

// .min-h-100 {
//   min-height: 100%;
// }

// .min-vh-100 {
//   min-height: 100vh;
// }
