html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ===============
    l a y o u t   
================== */

.row {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}
 
.row-nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;  
  height: 60px;
  @media #{$breakpoint-medium} { 
    height: initial;
  }
    
}

.home {
  .row-nav {
    @media #{$breakpoint-small-only} {
      height: 0px;
    }
  }
}

body {
  padding: 0;
  @media #{$breakpoint-medium} { 
    padding: initial;
  }
}

article,
main {
  clear: both;
  position: relative;
  width: 100%;
  position: inherit;
}

.wrap {
  clear: both;
  position: relative;
  width: 100%;
}

.content {
  min-height: calc(100vh - 90px);
}

