// Converted Variables

// Custom Media Query Variables

/*

   COORDINATES
   Docs: http://tachyons.io/docs/layout/position/

   Use in combination with the position module.

   Base:
     top
     bottom
     right
     left

   Modifiers:
     -0  = literal value 0
     -1  = literal value 1
     -2  = literal value 2
     --1 = literal value -1
     --2 = literal value -2

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/


.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}

.top-1 {
  top: 1rem;
}
.right-1 {
  right: 1rem;
}
.bottom-1 {
  bottom: 1rem;
}
.left-1 {
  left: 1rem;
}

.top-2 {
  top: 2rem;
}
.right-2 {
  right: 2rem;
}
.bottom-2 {
  bottom: 2rem;
}
.left-2 {
  left: 2rem;
}

.top-3 {
  top: 3rem;
}

.top-m1 {
  top: -1rem;
}

.top-m1px {
  top: -1px;
}

.top-m2px {
  top: -2px;
}

.top-m3px {
  top: -3px;
}

.top-2px {
  top: 2px;
}

.right--1 {
  right: -1rem;
}
.bottom--1 {
  bottom: -1rem;
}
.left--1 {
  left: -1rem;
}

.top--2 {
  top: -2rem;
}
.right--2 {
  right: -2rem;
}
.bottom--2 {
  bottom: -2rem;
}
.left--2 {
  left: -2rem;
}

.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media #{$breakpoint-small} {
  .top-0-s {
    top: 0;
  }
  .left-0-s {
    left: 0;
  }
  .right-0-s {
    right: 0;
  }
  .bottom-0-s {
    bottom: 0;
  }
  .top-1-s {
    top: 1rem;
  }
  .left-1-s {
    left: 1rem;
  }
  .right-1-s {
    right: 1rem;
  }
  .bottom-1-s {
    bottom: 1rem;
  }
  .top-2-s {
    top: 2rem;
  }
  .left-2-s {
    left: 2rem;
  }
  .right-2-s {
    right: 2rem;
  }
  .bottom-2-s {
    bottom: 2rem;
  }
  .top--1-s {
    top: -1rem;
  }
  .right--1-s {
    right: -1rem;
  }
  .bottom--1-s {
    bottom: -1rem;
  }
  .left--1-s {
    left: -1rem;
  }
  .top--2-s {
    top: -2rem;
  }
  .right--2-s {
    right: -2rem;
  }
  .bottom--2-s {
    bottom: -2rem;
  }
  .left--2-s {
    left: -2rem;
  }
  .absolute--fill-s {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media #{$breakpoint-medium} {
  .top-0-m {
    top: 0;
  }
  .left-0-m {
    left: 0;
  }
  .right-0-m {
    right: 0;
  }
  .bottom-0-m {
    bottom: 0;
  }
  .top-1-m {
    top: 1rem;
  }
  .left-1-m {
    left: 1rem;
  }
  .right-1-m {
    right: 1rem;
  }
  .bottom-1-m {
    bottom: 1rem;
  }
  .top-2-m {
    top: 2rem;
  }
  .left-2-m {
    left: 2rem;
  }
  .right-2-m {
    right: 2rem;
  }
  .bottom-2-m {
    bottom: 2rem;
  }
  .top--1-m {
    top: -1rem;
  }
  .right--1-m {
    right: -1rem;
  }
  .bottom--1-m {
    bottom: -1rem;
  }
  .left--1-m {
    left: -1rem;
  }
  .top--2-m {
    top: -2rem;
  }
  .right--2-m {
    right: -2rem;
  }
  .bottom--2-m {
    bottom: -2rem;
  }
  .left--2-m {
    left: -2rem;
  }
  .absolute--fill-m {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media #{$breakpoint-large} {
  .top-0-l {
    top: 0;
  }
  .left-0-l {
    left: 0;
  }
  .right-0-l {
    right: 0;
  }
  .bottom-0-l {
    bottom: 0;
  }
  .top-1-l {
    top: 1rem;
  }
  .left-1-l {
    left: 1rem;
  }
  .right-1-l {
    right: 1rem;
  }
  .bottom-1-l {
    bottom: 1rem;
  }
  .top-2-l {
    top: 2rem;
  }
  .left-2-l {
    left: 2rem;
  }
  .right-2-l {
    right: 2rem;
  }
  .bottom-2-l {
    bottom: 2rem;
  }
  .top--1-l {
    top: -1rem;
  }
  .right--1-l {
    right: -1rem;
  }
  .bottom--1-l {
    bottom: -1rem;
  }
  .left--1-l {
    left: -1rem;
  }
  .top--2-l {
    top: -2rem;
  }
  .right--2-l {
    right: -2rem;
  }
  .bottom--2-l {
    bottom: -2rem;
  }
  .left--2-l {
    left: -2rem;
  }
  .absolute--fill-l {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
