// Converted Variables

// Custom Media Query Variables

/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.na1 {
  margin: -$spacing-extra-small;
}
.na2 {
  margin: -$spacing-small;
}
.na3 {
  margin: -$spacing-medium;
}
.na4 {
  margin: -$spacing-large;
}
.na5 {
  margin: -$spacing-extra-large;
}
.na6 {
  margin: -$spacing-extra-extra-large;
}
.na7 {
  margin: -$spacing-extra-extra-extra-large;
}

.nl1 {
  margin-left: -$spacing-extra-small;
}
.nl2 {
  margin-left: -$spacing-small;
}
.nl3 {
  margin-left: -$spacing-medium;
}
.nl4 {
  margin-left: -$spacing-large;
}
.nl5 {
  margin-left: -$spacing-extra-large;
}
.nl6 {
  margin-left: -$spacing-extra-extra-large;
}
.nl7 {
  margin-left: -$spacing-extra-extra-extra-large;
}

.nr1 {
  margin-right: -$spacing-extra-small;
}
.nr2 {
  margin-right: -$spacing-small;
}
.nr3 {
  margin-right: -$spacing-medium;
}
.nr4 {
  margin-right: -$spacing-large;
}
.nr5 {
  margin-right: -$spacing-extra-large;
}
.nr6 {
  margin-right: -$spacing-extra-extra-large;
}
.nr7 {
  margin-right: -$spacing-extra-extra-extra-large;
}

.nb1 {
  margin-bottom: -$spacing-extra-small;
}
.nb2 {
  margin-bottom: -$spacing-small;
}
.nb3 {
  margin-bottom: -$spacing-medium;
}
.nb4 {
  margin-bottom: -$spacing-large;
}
.nb5 {
  margin-bottom: -$spacing-extra-large;
}
.nb6 {
  margin-bottom: -$spacing-extra-extra-large;
}
.nb7 {
  margin-bottom: -$spacing-extra-extra-extra-large;
}

.nt1 {
  margin-top: -$spacing-extra-small;
}
.nt2 {
  margin-top: -$spacing-small;
}
.nt3 {
  margin-top: -$spacing-medium;
}
.nt4 {
  margin-top: -$spacing-large;
}
.nt5 {
  margin-top: -$spacing-extra-large;
}
.nt6 {
  margin-top: -$spacing-extra-extra-large;
}
.nt7 {
  margin-top: -$spacing-extra-extra-extra-large;
}

@media #{$breakpoint-small} {
  .na1-s {
    margin: -$spacing-extra-small;
  }
  .na2-s {
    margin: -$spacing-small;
  }
  .na3-s {
    margin: -$spacing-medium;
  }
  .na4-s {
    margin: -$spacing-large;
  }
  .na5-s {
    margin: -$spacing-extra-large;
  }
  .na6-s {
    margin: -$spacing-extra-extra-large;
  }
  .na7-s {
    margin: -$spacing-extra-extra-extra-large;
  }

  .nl1-s {
    margin-left: -$spacing-extra-small;
  }
  .nl2-s {
    margin-left: -$spacing-small;
  }
  .nl3-s {
    margin-left: -$spacing-medium;
  }
  .nl4-s {
    margin-left: -$spacing-large;
  }
  .nl5-s {
    margin-left: -$spacing-extra-large;
  }
  .nl6-s {
    margin-left: -$spacing-extra-extra-large;
  }
  .nl7-s {
    margin-left: -$spacing-extra-extra-extra-large;
  }

  .nr1-s {
    margin-right: -$spacing-extra-small;
  }
  .nr2-s {
    margin-right: -$spacing-small;
  }
  .nr3-s {
    margin-right: -$spacing-medium;
  }
  .nr4-s {
    margin-right: -$spacing-large;
  }
  .nr5-s {
    margin-right: -$spacing-extra-large;
  }
  .nr6-s {
    margin-right: -$spacing-extra-extra-large;
  }
  .nr7-s {
    margin-right: -$spacing-extra-extra-extra-large;
  }

  .nb1-s {
    margin-bottom: -$spacing-extra-small;
  }
  .nb2-s {
    margin-bottom: -$spacing-small;
  }
  .nb3-s {
    margin-bottom: -$spacing-medium;
  }
  .nb4-s {
    margin-bottom: -$spacing-large;
  }
  .nb5-s {
    margin-bottom: -$spacing-extra-large;
  }
  .nb6-s {
    margin-bottom: -$spacing-extra-extra-large;
  }
  .nb7-s {
    margin-bottom: -$spacing-extra-extra-extra-large;
  }

  .nt1-s {
    margin-top: -$spacing-extra-small;
  }
  .nt2-s {
    margin-top: -$spacing-small;
  }
  .nt3-s {
    margin-top: -$spacing-medium;
  }
  .nt4-s {
    margin-top: -$spacing-large;
  }
  .nt5-s {
    margin-top: -$spacing-extra-large;
  }
  .nt6-s {
    margin-top: -$spacing-extra-extra-large;
  }
  .nt7-s {
    margin-top: -$spacing-extra-extra-extra-large;
  }
}

@media #{$breakpoint-medium} {
  .na1-m {
    margin: -$spacing-extra-small;
  }
  .na2-m {
    margin: -$spacing-small;
  }
  .na3-m {
    margin: -$spacing-medium;
  }
  .na4-m {
    margin: -$spacing-large;
  }
  .na5-m {
    margin: -$spacing-extra-large;
  }
  .na6-m {
    margin: -$spacing-extra-extra-large;
  }
  .na7-m {
    margin: -$spacing-extra-extra-extra-large;
  }

  .nl1-m {
    margin-left: -$spacing-extra-small;
  }
  .nl2-m {
    margin-left: -$spacing-small;
  }
  .nl3-m {
    margin-left: -$spacing-medium;
  }
  .nl4-m {
    margin-left: -$spacing-large;
  }
  .nl5-m {
    margin-left: -$spacing-extra-large;
  }
  .nl6-m {
    margin-left: -$spacing-extra-extra-large;
  }
  .nl7-m {
    margin-left: -$spacing-extra-extra-extra-large;
  }

  .nr1-m {
    margin-right: -$spacing-extra-small;
  }
  .nr2-m {
    margin-right: -$spacing-small;
  }
  .nr3-m {
    margin-right: -$spacing-medium;
  }
  .nr4-m {
    margin-right: -$spacing-large;
  }
  .nr5-m {
    margin-right: -$spacing-extra-large;
  }
  .nr6-m {
    margin-right: -$spacing-extra-extra-large;
  }
  .nr7-m {
    margin-right: -$spacing-extra-extra-extra-large;
  }

  .nb1-m {
    margin-bottom: -$spacing-extra-small;
  }
  .nb2-m {
    margin-bottom: -$spacing-small;
  }
  .nb3-m {
    margin-bottom: -$spacing-medium;
  }
  .nb4-m {
    margin-bottom: -$spacing-large;
  }
  .nb5-m {
    margin-bottom: -$spacing-extra-large;
  }
  .nb6-m {
    margin-bottom: -$spacing-extra-extra-large;
  }
  .nb7-m {
    margin-bottom: -$spacing-extra-extra-extra-large;
  }

  .nt1-m {
    margin-top: -$spacing-extra-small;
  }
  .nt2-m {
    margin-top: -$spacing-small;
  }
  .nt3-m {
    margin-top: -$spacing-medium;
  }
  .nt4-m {
    margin-top: -$spacing-large;
  }
  .nt5-m {
    margin-top: -$spacing-extra-large;
  }
  .nt6-m {
    margin-top: -$spacing-extra-extra-large;
  }
  .nt7-m {
    margin-top: -$spacing-extra-extra-extra-large;
  }
}

@media #{$breakpoint-large} {
  .na1-l {
    margin: -$spacing-extra-small;
  }
  .na2-l {
    margin: -$spacing-small;
  }
  .na3-l {
    margin: -$spacing-medium;
  }
  .na4-l {
    margin: -$spacing-large;
  }
  .na5-l {
    margin: -$spacing-extra-large;
  }
  .na6-l {
    margin: -$spacing-extra-extra-large;
  }
  .na7-l {
    margin: -$spacing-extra-extra-extra-large;
  }

  .nl1-l {
    margin-left: -$spacing-extra-small;
  }
  .nl2-l {
    margin-left: -$spacing-small;
  }
  .nl3-l {
    margin-left: -$spacing-medium;
  }
  .nl4-l {
    margin-left: -$spacing-large;
  }
  .nl5-l {
    margin-left: -$spacing-extra-large;
  }
  .nl6-l {
    margin-left: -$spacing-extra-extra-large;
  }
  .nl7-l {
    margin-left: -$spacing-extra-extra-extra-large;
  }

  .nr1-l {
    margin-right: -$spacing-extra-small;
  }
  .nr2-l {
    margin-right: -$spacing-small;
  }
  .nr3-l {
    margin-right: -$spacing-medium;
  }
  .nr4-l {
    margin-right: -$spacing-large;
  }
  .nr5-l {
    margin-right: -$spacing-extra-large;
  }
  .nr6-l {
    margin-right: -$spacing-extra-extra-large;
  }
  .nr7-l {
    margin-right: -$spacing-extra-extra-extra-large;
  }

  .nb1-l {
    margin-bottom: -$spacing-extra-small;
  }
  .nb2-l {
    margin-bottom: -$spacing-small;
  }
  .nb3-l {
    margin-bottom: -$spacing-medium;
  }
  .nb4-l {
    margin-bottom: -$spacing-large;
  }
  .nb5-l {
    margin-bottom: -$spacing-extra-large;
  }
  .nb6-l {
    margin-bottom: -$spacing-extra-extra-large;
  }
  .nb7-l {
    margin-bottom: -$spacing-extra-extra-extra-large;
  }

  .nt1-l {
    margin-top: -$spacing-extra-small;
  }
  .nt2-l {
    margin-top: -$spacing-small;
  }
  .nt3-l {
    margin-top: -$spacing-medium;
  }
  .nt4-l {
    margin-top: -$spacing-large;
  }
  .nt5-l {
    margin-top: -$spacing-extra-large;
  }
  .nt6-l {
    margin-top: -$spacing-extra-extra-large;
  }
  .nt7-l {
    margin-top: -$spacing-extra-extra-extra-large;
  }
}
