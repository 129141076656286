#click-slider {
  @media #{$breakpoint-large} {
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20fr 1fr;
  }
  @media #{$breakpoint-medium-only} {
    min-height: 90vh;
  }
  @media #{$breakpoint-small-only} {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  }
  @media #{$breakpoint-small-landscape} {
    min-height: 800px;
    padding-top: 4rem;
  }

  .row {
    @media #{$breakpoint-small-only} {
      padding-left: 0rem;
      padding-right: 0rem;
      padding-top: 0;
    }
  }
}

#slider > div > div {
  @media #{$breakpoint-small-only} {
    top: 4rem;
    position: relative;
  }
}

#slider,
.slide-content {
  width: 100%;
}
 
.slide {
  @media #{$breakpoint-large} {
    min-height: 840px;
  }
}

#slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  cursor: pointer;
  position: absolute;
  background-image: url(../img/arrow.svg);
  background-size: 100%;
  height: 20px;
  width: 21px;
}

#arrow-left {
  left: 0;
  transform: rotate(180deg);
  @media only screen and (max-width: 640px)  { 
    bottom: 5rem;
  }
}

#arrow-right {
  right: 0;
  @media only screen and (max-width: 640px)  { 
    bottom: 5rem;
  }
}

.thumb-slides-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  max-width: 750px;
  @media only screen and (max-width: 640px)  { 
    grid-template-columns: 1fr 1fr;
    max-height: 80vh;
  }
}
