// Converted Variables

// Custom Media Query Variables

/*

   ROTATIONS

*/

.rotate-45 {
  transform: rotate(45deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-135 {
  transform: rotate(135deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-225 {
  transform: rotate(225deg);
}
.rotate-270 {
  transform: rotate(270deg);
}
.rotate-315 {
  transform: rotate(315deg);
}

@media #{$breakpoint-small} {
  .rotate-45-s {
    transform: rotate(45deg);
  }
  .rotate-90-s {
    transform: rotate(90deg);
  }
  .rotate-135-s {
    transform: rotate(135deg);
  }
  .rotate-180-s {
    transform: rotate(180deg);
  }
  .rotate-225-s {
    transform: rotate(225deg);
  }
  .rotate-270-s {
    transform: rotate(270deg);
  }
  .rotate-315-s {
    transform: rotate(315deg);
  }
}

@media #{$breakpoint-medium} {
  .rotate-45-m {
    transform: rotate(45deg);
  }
  .rotate-90-m {
    transform: rotate(90deg);
  }
  .rotate-135-m {
    transform: rotate(135deg);
  }
  .rotate-180-m {
    transform: rotate(180deg);
  }
  .rotate-225-m {
    transform: rotate(225deg);
  }
  .rotate-270-m {
    transform: rotate(270deg);
  }
  .rotate-315-m {
    transform: rotate(315deg);
  }
}

@media #{$breakpoint-large} {
  .rotate-45-l {
    transform: rotate(45deg);
  }
  .rotate-90-l {
    transform: rotate(90deg);
  }
  .rotate-135-l {
    transform: rotate(135deg);
  }
  .rotate-180-l {
    transform: rotate(180deg);
  }
  .rotate-225-l {
    transform: rotate(225deg);
  }
  .rotate-270-l {
    transform: rotate(270deg);
  }
  .rotate-315-l {
    transform: rotate(315deg);
  }
}
