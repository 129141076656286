// grid test

.grid {
  display: grid;
}
 
$templateColumns: (
  "grid--tc2": repeat(2, 1fr),
  "grid--tc3": repeat(3, 1fr),
  "grid--tc4": repeat(4, 1fr),
  "grid--tc5": repeat(5, 1fr),
  "grid--tc6": repeat(6, 1fr),
  "grid--141": 1fr 4fr 1fr,
);

@each $name, $column in $templateColumns {
  .#{$name} {
    grid-template-columns: $column;
  }
}
 
@media #{$breakpoint-small} {  
  @each $name, $column in $templateColumns {
    .#{$name}-s {
      grid-template-columns: $column;
    }
  }
}

@media #{$breakpoint-medium} {  
  @each $name, $column in $templateColumns {
    .#{$name}-m {
      grid-template-columns: $column;
    }
  }
}

@media #{$breakpoint-large} {  
  @each $name, $column in $templateColumns {
    .#{$name}-l {
      grid-template-columns: $column;
    }
  }
}

// CUSTOM GRID TEMLATE COLUMNS

@media #{$breakpoint-large} {
  .grid--tc3c-l {
    grid-template-columns: 1fr 1.044fr 1fr;
  }
}

$columnGaps: (
  "grid--cg0": 0rem,
  "grid--cg1": 1rem,
  "grid--cg2": 2rem,
  "grid--cg3": 3rem,
  "grid--cg4": 4rem,
  "grid--cg5": 5rem,
  "grid--cg6": 6rem,
  "grid--cg7": 7rem,

);

// CSS GRID COLUMN GAP

@each $name, $gap in $columnGaps {
  .#{$name} {
    grid-column-gap: $gap;
  }
}

@media #{$breakpoint-small} { 
  @each $name, $gap in $columnGaps {
    .#{$name}-s {
      grid-column-gap: $gap;
    }
  }
}

@media #{$breakpoint-medium} { 
  @each $name, $gap in $columnGaps {
    .#{$name}-m {
      grid-column-gap: $gap;
    }
  }
}

@media #{$breakpoint-large} { 
  @each $name, $gap in $columnGaps {
    .#{$name}-l {
      grid-column-gap: $gap;
    }
  }
}