// Converted Variables

// Custom Media Query Variables

/* Variables */

/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers: 
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -s = small
     -m = medium
     -l = large

*/

// padding
  
$spaces: (
  "0": $spacing-none,
  "1": $spacing-extra-small,
  "2": $spacing-small,
  "3": $spacing-medium,
  "4": $spacing-large,
  "5": $spacing-extra-large,
  "6": $spacing-extra-extra-large,
  "7": $spacing-extra-extra-extra-large,
  "80m": -80px,
  "5px": 5px,
  "10": 10px,
  "12": 12px,
  "13": 13px,
  "15": 15px,
  "20": 20px,
  "25": 25px,
  "30": 30px,
  "40": 40px,
  "50": 50px,
  "60": 60px,
  "70": 70px,
  "80": 80px,
  "90": 90px,
  "100": 100px,
  "105": 105px,
  "200": 200px
);
           
@each $name, $space in $spaces {
  .p#{$name} {
    padding: $space;
  }
  .pl#{$name} {
    padding-left: $space;
  }
  .pr#{$name} {
    padding-right: $space;
  }
  .pb#{$name} {
    padding-bottom: $space;
  }
  .pt#{$name} {
    padding-top: $space;
  }
  .pv#{$name} {
    padding-top: $space;
    padding-bottom: $space;
  }
  .ph#{$name} {
    padding-left: $space;
    padding-right: $space;
  }
  .m#{$name} {
    margin: $space;
  }
  .ml#{$name} {
    margin-left: $space;
  }
  .mr#{$name} {
    margin-right: $space;
  }
  .mb#{$name} {
    margin-bottom: $space;
  }
  .mt#{$name} {
    margin-top: $space;
  }
  .mv#{$name} {
    margin-top: $space;
    margin-bottom: $space;
  }
  .mh#{$name} {
    margin-left: $space;
    margin-right: $space;
  }
}

// breakpoint small
@media #{$breakpoint-small} {
  @each $name, $space in $spaces {
    .p#{$name}-s {
      padding: $space;
    }
    .pl#{$name}-s {
      padding-left: $space;
    }
    .pr#{$name}-s {
      padding-right: $space;
    }
    .pb#{$name}-s {
      padding-bottom: $space;
    }
    .pt#{$name}-s {
      padding-top: $space;
    }
    .pv#{$name}-s {
      padding-top: $space;
      padding-bottom: $space;
    }
    .ph#{$name}-s {
      padding-left: $space;
      padding-right: $space;
    }
    .m#{$name}-s {
      margin: $space;
    }
    .ml#{$name}-s {
      margin-left: $space;
    }
    .mr#{$name}-s {
      margin-right: $space;
    }
    .mb#{$name}-s {
      margin-bottom: $space;
    }
    .mt#{$name}-s {
      margin-top: $space;
    }
    .mv#{$name}-s {
      margin-top: $space;
      margin-bottom: $space;
    }
    .mh#{$name}-s {
      margin-left: $space;
      margin-right: $space;
    }
  }
}

// breakpoint small landscape
@media #{$breakpoint-small-landscape} {
  @each $name, $space in $spaces {
    .p#{$name}-sl {
      padding: $space;
    }
    .pl#{$name}-sl {
      padding-left: $space;
    }
    .pr#{$name}-sl {
      padding-right: $space;
    }
    .pb#{$name}-sl {
      padding-bottom: $space;
    }
    .pt#{$name}-sl {
      padding-top: $space;
    }
    .pv#{$name}-sl {
      padding-top: $space;
      padding-bottom: $space;
    }
    .ph#{$name}-sl {
      padding-left: $space;
      padding-right: $space;
    }
    .m#{$name}-sl {
      margin: $space;
    }
    .ml#{$name}-sl {
      margin-left: $space;
    }
    .mr#{$name}-sl {
      margin-right: $space;
    }
    .mb#{$name}-sl {
      margin-bottom: $space;
    }
    .mt#{$name}-sl {
      margin-top: $space;
    }
    .mv#{$name}-sl {
      margin-top: $space;
      margin-bottom: $space;
    }
    .mh#{$name}-sl {
      margin-left: $space;
      margin-right: $space;
    }
  }
}

// breakpoint medium
@media #{$breakpoint-medium} {
  @each $name, $space in $spaces {
    .p#{$name}-m {
      padding: $space;
    }
    .pl#{$name}-m {
      padding-left: $space;
    }
    .pr#{$name}-m {
      padding-right: $space;
    }
    .pb#{$name}-m {
      padding-bottom: $space;
    }
    .pt#{$name}-m {
      padding-top: $space;
    }
    .pv#{$name}-m {
      padding-top: $space;
      padding-bottom: $space;
    }
    .ph#{$name}-m {
      padding-left: $space;
      padding-right: $space;
    }
    .m#{$name}-m {
      margin: $space;
    }
    .ml#{$name}-m {
      margin-left: $space;
    }
    .mr#{$name}-m {
      margin-right: $space;
    }
    .mb#{$name}-m {
      margin-bottom: $space;
    }
    .mt#{$name}-m {
      margin-top: $space;
    }
    .mv#{$name}-m {
      margin-top: $space;
      margin-bottom: $space;
    }
    .mh#{$name}-m {
      margin-left: $space;
      margin-right: $space;
    }
  }
}

// breakpoint medium
@media #{$breakpoint-medium-landscape} {
  @each $name, $space in $spaces {
    .p#{$name}-ml {
      padding: $space;
    }
    .pl#{$name}-ml {
      padding-left: $space;
    }
    .pr#{$name}-ml {
      padding-right: $space;
    }
    .pb#{$name}-ml {
      padding-bottom: $space;
    }
    .pt#{$name}-ml {
      padding-top: $space;
    }
    .pv#{$name}-ml {
      padding-top: $space;
      padding-bottom: $space;
    }
    .ph#{$name}-ml {
      padding-left: $space;
      padding-right: $space;
    }
    .m#{$name}-ml {
      margin: $space;
    }
    .ml#{$name}-ml {
      margin-left: $space;
    }
    .mr#{$name}-ml {
      margin-right: $space;
    }
    .mb#{$name}-ml {
      margin-bottom: $space;
    }
    .mt#{$name}-ml {
      margin-top: $space;
    }
    .mv#{$name}-ml {
      margin-top: $space;
      margin-bottom: $space;
    }
    .mh#{$name}-ml {
      margin-left: $space;
      margin-right: $space;
    }
  }
}

// breakpoint large
@media #{$breakpoint-large} {
  @each $name, $space in $spaces {
    .p#{$name}-l {
      padding: $space;
    }
    .pl#{$name}-l {
      padding-left: $space;
    }
    .pr#{$name}-l {
      padding-right: $space;
    }
    .pb#{$name}-l {
      padding-bottom: $space;
    }
    .pt#{$name}-l {
      padding-top: $space;
    }
    .pv#{$name}-l {
      padding-top: $space;
      padding-bottom: $space;
    }
    .ph#{$name}-l {
      padding-left: $space;
      padding-right: $space;
    }
    .m#{$name}-l {
      margin: $space;
    }
    .ml#{$name}-l {
      margin-left: $space;
    }
    .mr#{$name}-l {
      margin-right: $space;
    }
    .mb#{$name}-l {
      margin-bottom: $space;
    }
    .mt#{$name}-l {
      margin-top: $space;
    }
    .mv#{$name}-l {
      margin-top: $space;
      margin-bottom: $space;
    }
    .mh#{$name}-l {
      margin-left: $space;
      margin-right: $space;
    }
  }
}
