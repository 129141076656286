// Converted Variables

// Custom Media Query Variables

/*

  BOX-SHADOW
  Docs: http://tachyons.io/docs/themes/box-shadow/

  Media Query Extensions:
   -s = small
   -m  = medium
   -l  = large

 */

.shadow-1 {
  box-shadow: $box-shadow-1;
}
.shadow-2 {
  box-shadow: $box-shadow-2;
}
.shadow-3 {
  box-shadow: $box-shadow-3;
}
.shadow-4 {
  box-shadow: $box-shadow-4;
}
.shadow-5 {
  box-shadow: $box-shadow-5;
}

@media #{$breakpoint-small} {
  .shadow-1-s {
    box-shadow: $box-shadow-1;
  }
  .shadow-2-s {
    box-shadow: $box-shadow-2;
  }
  .shadow-3-s {
    box-shadow: $box-shadow-3;
  }
  .shadow-4-s {
    box-shadow: $box-shadow-4;
  }
  .shadow-5-s {
    box-shadow: $box-shadow-5;
  }
}

@media #{$breakpoint-medium} {
  .shadow-1-m {
    box-shadow: $box-shadow-1;
  }
  .shadow-2-m {
    box-shadow: $box-shadow-2;
  }
  .shadow-3-m {
    box-shadow: $box-shadow-3;
  }
  .shadow-4-m {
    box-shadow: $box-shadow-4;
  }
  .shadow-5-m {
    box-shadow: $box-shadow-5;
  }
}

@media #{$breakpoint-large} {
  .shadow-1-l {
    box-shadow: $box-shadow-1;
  }
  .shadow-2-l {
    box-shadow: $box-shadow-2;
  }
  .shadow-3-l {
    box-shadow: $box-shadow-3;
  }
  .shadow-4-l {
    box-shadow: $box-shadow-4;
  }
  .shadow-5-l {
    box-shadow: $box-shadow-5;
  }
}
