/*!!!

# MIN HEIGHT

### Modifiers

- min = min-width
- number = width in pixels
- number > 100 = width in pixels
- number < 100 = width in %

*/

.mih-100 {
  min-height: 101px;
}
.mih-150 {
  min-height: 150px;
}
.mih-160 {
  min-height: 160px;
}
.mih-170 {
  min-height: 170px;
}

@media #{$breakpoint-medium} {  
  .mih-970-m {
    min-height: 970px;
  }
}

@media #{$breakpoint-large} {  
  .mih-380-l {
    min-height: 380px;
  }
}

.mih-80vh {
  min-height: 80vh;
}

