// Converted Variables

// Custom Media Query Variables

/*

   TEXT DECORATION
   Docs: http://tachyons.io/docs/typography/text-decoration/


   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.strike {
  text-decoration: line-through;
}
.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
}

@media #{$breakpoint-small} {
  .strike-s {
    text-decoration: line-through;
  }
  .underline-s {
    text-decoration: underline;
  }
  .no-underline-s {
    text-decoration: none;
  }
}

@media #{$breakpoint-medium} {
  .strike-m {
    text-decoration: line-through;
  }
  .underline-m {
    text-decoration: underline;
  }
  .no-underline-m {
    text-decoration: none;
  }
}

@media #{$breakpoint-large} {
  .strike-l {
    text-decoration: line-through;
  }
  .underline-l {
    text-decoration: underline;
  }
  .no-underline-l {
    text-decoration: none;
  }
}
