// Converted Variables

// Custom Media Query Variables

/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large
*/

/*
 * For Hero/Marketing Titles
 *
 * These generally are too large for mobile
 * so be careful using them on smaller screens.
 * */

// .f-6,
// .f-headline {
//   font-size: $font-size-headline;
// }
// .f-5,
// .f-subheadline {
//   font-size: $font-size-subheadline;
// }

$fonts: (
  "0": $font-size-0,
  "1": $font-size-1,
  "2": $font-size-2,
  "3": $font-size-3,
  "4": $font-size-4,
  "5": $font-size-5,
  "6": $font-size-6,
  "7": $font-size-7,
  "8": $font-size-8,
  "9": $font-size-9,
  "10": $font-size-10,
  "11": $font-size-11
);

@each $name, $font in $fonts {
  .f#{$name} {
    font-size: $font;
  }
}

@media #{$breakpoint-small} {
  @each $name, $font in $fonts {
    .f#{$name}-s {
      font-size: $font;
    }
  }
}

@media #{$breakpoint-small-landscape} {
  @each $name, $font in $fonts {
    .f#{$name}-sl {
      font-size: $font;
    }
  }
}


@media #{$breakpoint-medium} {
  @each $name, $font in $fonts {
    .f#{$name}-m {
      font-size: $font;
    }
  }
}

@media #{$breakpoint-large} {
  @each $name, $font in $fonts {
    .f#{$name}-l {
      font-size: $font;
    }
  }
} 