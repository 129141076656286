// Converted Variables

// Custom Media Query Variables

/*

   BACKGROUND SIZE
   Docs: http://tachyons.io/docs/themes/background-size/

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

/*
  Often used in combination with background image set as an inline style
  on an html element.
*/

.cover {
  background-size: cover !important;
}
.contain {
  background-size: contain !important;
}

@media #{$breakpoint-small} {
  .cover-s {
    background-size: cover !important;
  }
  .contain-s {
    background-size: contain !important;
  }
}

@media #{$breakpoint-medium} {
  .cover-m {
    background-size: cover !important;
  }
  .contain-m {
    background-size: contain !important;
  }
}

@media #{$breakpoint-large} {
  .cover-l {
    background-size: cover !important;
  }
  .contain-l {
    background-size: contain !important;
  }
}
