// Converted Variables

// Custom Media Query Variables

/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/

.link {
  text-decoration: none;
  transition: color 0.15s ease-in;
}

.link:link,
.link:visited {
  transition: color 0.15s ease-in;
}
.link:hover {
  transition: color 0.15s ease-in;
}
.link:active {
  transition: color 0.15s ease-in;
}
.link:focus {
  // transition: color .15s ease-in;
  // outline: 1px dotted currentColor;
}
