// normalize

// ! TACHYONS X (custom scss version of 7) | http://tachyons.io
//      ________            ______
//      ___  __/_____ _________  /______  ______________________
//      __  /  _  __ `/  ___/_  __ \_  / / /  __ \_  __ \_  ___/
//      _  /   / /_/ // /__ _  / / /  /_/ // /_/ /  / / /(__  )
//      /_/    \__,_/ \___/ /_/ /_/_\__, / \____//_/ /_//____/
//
//
//
//  External Library Includes
@import "tachyons/normalize";

//  Variables
//  Importing here will allow you to override any variables in the modules
// 
@import "tachyons/variables";

//  Debugging
// @import "tachyons/debug-children";
// @import "tachyons/debug-grid";

//  Uncomment out the line below to help debug layout issues
//  @import 'tachyons/debug';

//  Modules
@import "tachyons/box-sizing";
@import "tachyons/images";
@import "tachyons/background-size";
@import "tachyons/background-position";
// @import "tachyons/outlines";
@import "tachyons/borders";
@import "tachyons/border-colors";
@import "tachyons/border-radius";
// @import "tachyons/border-style";
@import "tachyons/border-widths";
@import "tachyons/box-shadow";
// @import "tachyons/code";
@import "tachyons/colors";
@import "tachyons/coordinates";
// @import "tachyons/clears";
@import "tachyons/display";
@import "tachyons/flexbox";
@import "tachyons/floats";
// @import "tachyons/font-family";
// @import "tachyons/font-style";
@import "tachyons/font-weight";
@import "tachyons/forms";
@import "tachyons/heights";
@import "tachyons/letter-spacing";
@import "tachyons/line-height";
@import "tachyons/links";
@import "tachyons/lists";
@import "tachyons/min-heights";
@import "tachyons/min-widths";
@import "tachyons/max-widths";
@import "tachyons/max-height";
@import "tachyons/widths";
@import "tachyons/overflow";
@import "tachyons/position";
@import "tachyons/opacity";
@import "tachyons/rotations";
// @import "tachyons/skins";
// @import "tachyons/skins-pseudo";
@import "tachyons/spacing";
@import "tachyons/css-grid";
@import "tachyons/aspect-ratios";
@import "tachyons/negative-margins";
@import "tachyons/tables";
@import "tachyons/text-decoration";
@import "tachyons/text-align";
@import "tachyons/text-transform";
@import "tachyons/type-scale";
@import "tachyons/typography";
@import "tachyons/utilities";
// @import "tachyons/visibility";
// @import "tachyons/white-space";
// @import "tachyons/vertical-align";
@import "tachyons/hovers";
@import "tachyons/z-index";
// @import "tachyons/nested";

// custom and carry forward

@import "suitcase/type";
@import "suitcase/colours";
@import "suitcase/buttons_icons";
@import "suitcase/layout";
@import "suitcase/underlines";
@import "suitcase/slider";
@import "suitcase/bgImageSlider";
@import "suitcase/animation";
@import "suitcase/nav";
@import "suitcase/lazyload";
@import "suitcase/non-renewable";
@import "suitcase/js-css";
@import "suitcase/after-elements";


.slide-info {
  @media #{$breakpoint-small-landscape} {
    min-height: 910px;
  }

  p {
    font-family: $font2;
    font-size: 1.3rem;
  }

  div {
    @media only screen and (max-width: 641px) {
      z-index: 1001;
    }
    z-index: 1000;
  }
}

.slide-gallery > div {
  z-index: 1000;
  @media #{$breakpoint-small-landscape} {
    min-height: 910px;
  }
}

.activeTab > .slide-info {
  display: block !important;
}

.activeTabGallery > .slide-gallery {
  display: block !important;
}

.slide {
  display: none;
}

.current {
  display: flex !important;
}

// .noCurrentSlide {
//   display: none;
// }

// about tabs

.about-list-active {
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  font-family: MessinaBold, Arial, sans-serif;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    border-width: 0 0 1px;
    border-style: solid;
  }
  &:hover {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -2px;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }
  @media #{$breakpoint-small-only} {
    padding-bottom: 0.1rem;
  }
}

.list-active {
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  font-family: MessinaBold, Arial, sans-serif;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    border-width: 0 0 1px;
    border-style: solid;
  }
  &:hover {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -2px;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }
  @media #{$breakpoint-small-only} {
    padding-bottom: 0.1rem;
  }
}
  
.link-active {
  position: relative;
  @media only screen and (min-width: 1025px) {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -5px;
      border-width: 0 0 1px;
      border-style: solid;
    }
    &:hover {
      &:after {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-width: 0 0 1px;
        border-style: solid;
      }
    }
  }
}

.about-content {
  @media #{$breakpoint-large} {
    min-height: 730px;
  }

  @media #{$breakpoint-medium-only} {
    min-height: 730px;
  }

  @media #{$breakpoint-small-only} {
    min-height: 500px;
  }
}

.about-labels {
  .list a {
    &:hover {
      font-family: MessinaBold, Arial, sans-serif;
    }
  }
}

.about-grid {
  display: grid;
  @media only screen and (min-width: 1200px) {
    grid-column-gap: 4rem;
  }
  @media #{$breakpoint-large} {
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 450px;
    grid-column-gap: 2rem;
  }
  @media #{$breakpoint-medium-only} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  @media #{$breakpoint-small-only} {
    grid-template-columns: 1fr;
  }
  @media #{$breakpoint-small-landscape} {
    grid-template-columns: 1fr 1fr;
  }
  
  grid-auto-rows: minmax(280px, auto);
  grid-row-gap: 2rem;

  .list {
    img {
      width: 100%;
    }
  }
}

.about-grid-open-content {
  display: none;
  p {
    font-family: $font2;
  }
}

.about-active {
  font-family: MessinaBold, Arial, sans-serif;
}

.half-opacity {
  opacity: 0.4;
}

.awards-row {
  border-bottom: 1px solid black;
  min-height: 80px;
  @media #{$breakpoint-small-only} {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2rem;
  }
  span {
    &:nth-child(1) {
      width: 15%;
      @media #{$breakpoint-small-only} {
        width: 49%;
        display: inline-block;
        padding-top: 2rem;
      }
    }
    &:nth-child(2) {
      width: 25%;
      @media #{$breakpoint-small-only} {
        width: 49%;
        display: inline-block;
        padding-top: 2rem;
      }
    }
    &:nth-child(3) {
      width: 60%;
      @media #{$breakpoint-small-only} {
        width: 100%;
        padding-top: 2rem;
        float: left;
      }
    }
  }
}

.home,
.nav-white {
  .link {
    color: white;
  }
}


.rural-logo {
  min-width: 185px;
}

#automaticSlider1,
#automaticSlider2 {
  @media #{$breakpoint-small-landscape} {
    height: 100vh;
  }
}


.pagination {
  @include after-underline();
  + a {
    &:hover {
      font-family: MessinaBold;
    }
  }
}

.pagination.active {
  font-family: MessinaBold;
}

.navigation-link {
  @media #{$breakpoint-small-only} {
    font-size: 3rem;
  }
}

.work-filter {
  .active {
    @media only screen and (min-width:1025px)  { 
      @include after-underline-two();
    }
  }
}

.view-slide-info {
  &:hover {
    @include after-underline();
  }
}

.active-tab {
  font-family: $font3;
  @include after-underline();
}

.about-filters {
  li {
    &:hover {
      font-family: $font3;
    }
  }
}

address {
  p {
    font-family: MessinaBook, Arial, sans-serif;
    line-height: 25px;
    font-size: 1.5rem;
  }
}

.sliderOpacity {
  opacity: 0.2;
}

.bottom--3 {
  bottom: -3rem;
}

#our-team {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}

#our-awards {
  display: block;
  position: relative;
  top: 0px;
  visibility: hidden;
}

#slider,
#click-slider {
  height: 98%;
}
 
.automatic-slider-img--fade {
  transition: opacity 2s ease-in;
}

.automatic-slider-img--fade + .automatic-slider-img--fade {
  opacity: 0;
}

.cat-grid:first-child .grid-col {
  margin-top: 0.5%;
}

.slide-info {
  p {
    font-family: MessinaBook, Arial, sans-serif;
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
}

#js-nav-peak {
  transition: top 0.8s;
}

.cat-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-column-gap: 3rem;
  @media only screen and (max-width: 1025px)  { 
    grid-template-columns: 1fr 1fr; 
  }
  @media only screen and (max-width: 520px)  { 
    grid-template-columns: 1fr; 
  }
  li {
    margin-bottom: 1rem;
  }
}

.work-with-us-p {
  p {
    margin-bottom: 1rem;
  }
} 

.projects-home {
  .grid-col {
    @media only screen and (max-width: 640px)  { 
      display: flex;
      flex-direction: column;
     }
  }
}  


h1 {
  display:  block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  font-size: 2px;
}

 
