// Converted Variables

// Custom Media Query Variables

/*

   BORDER WIDTHS
   Docs: http://tachyons.io/docs/themes/borders/

   Base:
     bw = border-width

   Modifiers:
     0 = 0 width border
     1 = 1st step in border-width scale
     2 = 2nd step in border-width scale
     3 = 3rd step in border-width scale
     4 = 4th step in border-width scale
     5 = 5th step in border-width scale

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.bw0 {
  border-width: $border-width-none;
}
.bw1 {
  border-width: $border-width-1;
}
.bw2 {
  border-width: $border-width-2;
}
.bw3 {
  border-width: $border-width-3;
}
.bw4 {
  border-width: $border-width-4;
}
.bw5 {
  border-width: $border-width-5;
}

/* Resets */
.bt-0 {
  border-top-width: $border-width-none;
}
.br-0 {
  border-right-width: $border-width-none;
}
.bb-0 {
  border-bottom-width: $border-width-none;
}
.bl-0 {
  border-left-width: $border-width-none;
}

@media #{$breakpoint-small} {
  .bw0-s {
    border-width: $border-width-none;
  }
  .bw1-s {
    border-width: $border-width-1;
  }
  .bw2-s {
    border-width: $border-width-2;
  }
  .bw3-s {
    border-width: $border-width-3;
  }
  .bw4-s {
    border-width: $border-width-4;
  }
  .bw5-s {
    border-width: $border-width-5;
  }
  .bt-0-s {
    border-top-width: $border-width-none;
  }
  .br-0-s {
    border-right-width: $border-width-none;
  }
  .bb-0-s {
    border-bottom-width: $border-width-none;
  }
  .bl-0-s {
    border-left-width: $border-width-none;
  }
}

@media #{$breakpoint-medium} {
  .bw0-m {
    border-width: $border-width-none;
  }
  .bw1-m {
    border-width: $border-width-1;
  }
  .bw2-m {
    border-width: $border-width-2;
  }
  .bw3-m {
    border-width: $border-width-3;
  }
  .bw4-m {
    border-width: $border-width-4;
  }
  .bw5-m {
    border-width: $border-width-5;
  }
  .bt-0-m {
    border-top-width: $border-width-none;
  }
  .br-0-m {
    border-right-width: $border-width-none;
  }
  .bb-0-m {
    border-bottom-width: $border-width-none;
  }
  .bl-0-m {
    border-left-width: $border-width-none;
  }
}

@media #{$breakpoint-large} {
  .bw0-l {
    border-width: $border-width-none;
  }
  .bw1-l {
    border-width: $border-width-1;
  }
  .bw2-l {
    border-width: $border-width-2;
  }
  .bw3-l {
    border-width: $border-width-3;
  }
  .bw4-l {
    border-width: $border-width-4;
  }
  .bw5-l {
    border-width: $border-width-5;
  }
  .bt-0-l {
    border-top-width: $border-width-none;
  }
  .br-0-l {
    border-right-width: $border-width-none;
  }
  .bb-0-l {
    border-bottom-width: $border-width-none;
  }
  .bl-0-l {
    border-left-width: $border-width-none;
  }
}
