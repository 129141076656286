// Converted Variables

// Custom Media Query Variables

/*

   MIN WIDTH
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     mw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

/* Max Width Percentages */

.miw-100 {
  min-width: 100%;
}

.miw-45 {
  min-width: 45%;
}

/* Max Width Scale */

.miw1 {
  min-width: $min-width-1;
}
.miw2 {
  min-width: $min-width-2;
}
.miw3 {
  min-width: $min-width-3;
}
.miw4 {
  min-width: $min-width-4;
}
.miw5 {
  min-width: $min-width-5;
}
.miw6 {
  min-width: $min-width-6;
}
.miw7 {
  min-width: $min-width-7;
}
.miw8 {
  min-width: $min-width-8;
}
.miw9 {
  min-width: $min-width-9;
}

/* max with px */

.miw-800 {
  min-width: 800px;
}

/* Max Width String Properties */

.miw-none {
  min-width: none;
}

@media #{$breakpoint-small} {
  .miw-100-s {
    min-width: 100%;
  }

  .miw1-s {
    min-width: $min-width-1;
  }
  .miw2-s {
    min-width: $min-width-2;
  }
  .miw3-s {
    min-width: $min-width-3;
  }
  .miw4-s {
    min-width: $min-width-4;
  }
  .miw5-s {
    min-width: $min-width-5;
  }
  .miw6-s {
    min-width: $min-width-6;
  }
  .miw7-s {
    min-width: $min-width-7;
  }
  .miw8-s {
    min-width: $min-width-8;
  }
  .miw9-s {
    min-width: $min-width-9;
  }

  .miw-none-s {
    min-width: none;
  }
}

@media #{$breakpoint-medium} {
  .miw-100-m {
    min-width: 100%;
  }

  .miw1-m {
    min-width: $min-width-1;
  }
  .miw2-m {
    min-width: $min-width-2;
  }
  .miw3-m {
    min-width: $min-width-3;
  }
  .miw4-m {
    min-width: $min-width-4;
  }
  .miw5-m {
    min-width: $min-width-5;
  }
  .miw6-m {
    min-width: $min-width-6;
  }
  .miw7-m {
    min-width: $min-width-7;
  }
  .miw8-m {
    min-width: $min-width-8;
  }
  .miw9-m {
    min-width: $min-width-9;
  }

  .miw-none-m {
    min-width: none;
  }
}

@media #{$breakpoint-large} {
  .miw-100-l {
    min-width: 100%;
  }

  .miw1-l {
    min-width: $min-width-1;
  }
  .miw2-l {
    min-width: $min-width-2;
  }
  .miw3-l {
    min-width: $min-width-3;
  }
  .miw4-l {
    min-width: $min-width-4;
  }
  .miw5-l {
    min-width: $min-width-5;
  }
  .miw6-l {
    min-width: $min-width-6;
  }
  .miw7-l {
    min-width: $min-width-7;
  }
  .miw8-l {
    min-width: $min-width-8;
  }
  .miw9-l {
    min-width: $min-width-9;
  }

  .miw-none-l {
    min-width: none;
  }
}

// pixel widths

$minWidths: (
  "100": 100px,
  "150": 150px, 
  "200": 200px,
  "250": 250px,
  "300": 300px,
  "320": 320px,
  "350": 350px,
  "380": 380px,
  "400": 400px,
  "450": 450px,
  "500": 500px,
  "600": 600px,
  "650": 650px,
  "700": 700px,
  "750": 750px,
  "800": 800px,
  "850": 850px,
  "900": 900px,
  "1000": 1000px,
  "1100": 1100px,
  "1200": 1200px,
  "1300": 1300px,
  "1400": 1400px,
  "1500": 1500px,
  "1600": 1600px,
  "1700": 1700px,
  "1800": 1800px
);

@each $name, $minWidth in $minWidths {
  .miw-#{$name} {
    min-width: $minWidth;
  }
}
 
@media #{$breakpoint-small} {
  @each $name, $minWidth in $minWidths {
    .miw-#{$name}-s {
      min-width: $minWidth;
    }
  }
}
 
@media #{$breakpoint-small-landscape} {
  @each $name, $minWidth in $minWidths {
    .miw-#{$name}-sl {
      min-width: $minWidth;
    }
  }
}

@media #{$breakpoint-medium} {
  @each $name, $minWidth in $minWidths {
    .miw-#{$name}-m {
      min-width: $minWidth;
    }
  }
}
@media #{$breakpoint-large} {
  @each $name, $minWidth in $minWidths {
    .miw-#{$name}-l {
      min-width: $minWidth;
    }
  }
}
