.aspect-ratio--5x7m {
  padding-bottom: 138.5%;
}

.ml-2_5p-m {
  @media #{$breakpoint-medium} {  
    margin-left: 2.5%;
  }
}

// projects remove elements

.work-project,
.homepage {
  footer {
    display: none;
  }
}
.work-project {
  main {
    height: 100vh;
  }

  .bg-white {
    background: transparent;
  }
}
