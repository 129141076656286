// colours

$white: #ffffff;
$black: #000000;

$light-grey: #fbf8f7;

.bg-white {
  background: white;
}
 
// .grey {
//   color: $grey;
// }

.light-grey {
  color: $light-grey;
}

.bg-grey {
  background-color: $light-grey;
}

.bg-lightgrey {
  background-color: #f8f8f8;
}
