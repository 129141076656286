// Converted Variables

// Custom Media Query Variables

/*

    BORDERS
    Docs: http://tachyons.io/docs/themes/borders/

    Base:
      b = border

    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/
.bn {
  border-style: none;
  border-width: 0;
}
.ba {
  border-style: solid;
  border-width: 1px;
}
.bt {
  border-top-style: solid;
  border-top-width: 1px;
}
.br {
  border-right-style: solid;
  border-right-width: 1px;
}
.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bl {
  border-left-style: solid;
  border-left-width: 1px;
}
.bh {
  border-left: 1px solid;
  border-right: 1px solid;
}

@media #{$breakpoint-small} {
  .ba-s {
    border-style: solid;
    border-width: 1px;
  }
  .bt-s {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-s {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-s {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-s {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-s {
    border-style: none;
    border-width: 0;
  }
}

@media #{$breakpoint-medium} {
  .ba-m {
    border-style: solid;
    border-width: 1px;
  }
  .bt-m {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-m {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-m {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-m {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-m {
    border-style: none;
    border-width: 0;
  }
}

@media #{$breakpoint-large} {
  .ba-l {
    border-style: solid;
    border-width: 1px;
  }
  .bt-l {
    border-top-style: solid;
    border-top-width: 1px;
  }
  .br-l {
    border-right-style: solid;
    border-right-width: 1px;
  }
  .bb-l {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .bl-l {
    border-left-style: solid;
    border-left-width: 1px;
  }
  .bn-l {
    border-style: none;
    border-width: 0;
  }
}
