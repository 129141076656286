/*!!!

# COLOR VARIABLES

### Grayscale

- Solids
- Transparencies for black and white, 0-100
- Custom colors based on palx
*/

$colours: (
  "transparent": transparent,
  "black-90": rgba(0, 0, 0, 0.9),
  "black-70": rgba(0, 0, 0, 0.7),
  "black-40": rgba(0, 0, 0, 0.4),
  "black-20": rgba(0, 0, 0, 0.2),
  "black-10": rgba(0, 0, 0, 0.1),
  "black-05": rgba(0, 0, 0, 0.05),
  "black-025": rgba(0, 0, 0, 0.025),
  "black-0125": rgba(0, 0, 0, 0.0125),
  "white-90": rgba(255, 255, 255, 0.9),
  "white-70": rgba(255, 255, 255, 0.7),
  "white-40": rgba(255, 255, 255, 0.4),
  "white-20": rgba(255, 255, 255, 0.2),
  "white-10": rgba(255, 255, 255, 0.1),
  "white-05": rgba(255, 255, 255, 0.05),
  "white-025": rgba(255, 255, 255, 0.025),
  "white-0125": rgba(255, 255, 255, 0.0125),
  "black": #000000,
  //"gray-0": #161a1d,
  //"gray-1": #242c34,
  //"gray-2": #364049,
  //"gray-3": #4b545c,
  //"gray-4": #626a71,
  //"gray-5": #798086,
  //"gray-6": #979797,
  //"gray-7": #a9aeb1,
  //"gray-8": #c3c5c8,
  //"gray-9": #d8d8d8,
  //"gray-10": #f7f7f7,
  "white": #ffffff,
  //"green-0": #044324,
  //"green-1": #05532d,
  //"green-2": #087640,
  //"green-3": #afa96e,
  ////"green-4": #0b9b53,
  ////"green-5": #0cad5d,
  ////"green-6": #0ec167,
  ////"green-7": #0fd472,
  ////"green-8": #10e87c,
  ////"green-9": #78f5a6,
  ////"green-10": #c3fbdf,
  ////"teal-0": #003d37,
  ////"teal-1": #004e46,
  ////"teal-2": #007166,
  ////"teal-3": #008477,
  ////"teal-4": #009788,
  ////"teal-5": #00ab9a,
  ////"teal-6": #00bfac,
  ////"teal-7": #00d3be,
  ////"teal-8": #00e8d1,
  ////"teal-9": #00fde4,
  ////"teal-10": #ccfffa,
  ////"cyan-0": #015151,
  ////"cyan-1": #046064,
  ////"cyan-2": #0f7f8a,
  ////"cyan-3": #148f9f,
  ////"cyan-4": #19a0b3,
  ////"cyan-5": #1eb1c9,
  ////"cyan-6": #24c2de,
  ////"cyan-7": #29d3f4,
  ////"cyan-8": #64e2fd,
  ////"cyan-9": #9cf0fe,
  ////"cyan-10": #c7fefe,
  //"blue-0": #002c52,
  //"blue-1": #003a6c,
  //"blue-2": #0057a2,
  //"blue-3": #0066bf,
  //"blue-4": #0076dc,
  //"blue-5": #0086fb,
  //"blue-6": #4d97ff,
  //"blue-7": #72a9ff,
  //"blue-8": #8fbbff,
  //"blue-9": #a8ceff,
  //"blue-10": #bfe1ff,
  //"indigo-0": #2c0599,
  //"indigo-1": #3706be,
  //"indigo-2": #5c26ef,
  //"indigo-3": #733ff1,
  //"indigo-4": #8655f3,
  //"indigo-5": #9769f5,
  //"indigo-6": #a67df7,
  //"indigo-7": #b491f9,
  //"indigo-8": #c2a6fb,
  //"indigo-9": #cebafc,
  //"indigo-10": #dacefd,
  //"purple-0": #500576,
  //"purple-1": #630691,
  //"purple-2": #8a09cb,
  //"purple-3": #9b7793,
  //"purple-4": #af2ef6,
  //"purple-5": #bb4ef7,
  //"purple-6": #c568f9,
  //"purple-7": #d080fa,
  //"purple-8": #d996fb,
  //"purple-9": #e1acfb,
  //"purple-10": #e9c2fc,
  //"pink-0": #640233,
  //"pink-1": #7c033f,
  //"pink-2": #ae0459,
  //"pink-3": #c80467,
  //"pink-4": #e30574,
  //"pink-5": #fb1d83,
  //"pink-6": #fe5195,
  //"pink-7": #ff73a7,
  //"pink-8": #fc9bb3,
  //"pink-9": #ffa9cc,
  //"pink-10": #fec1df,
  //"red-0": #390408,
  //"red-1": #520814,
  //"red-2": #8a1223,
  //"red-3": #be3a34,
  //"red-4": #c61d33,
  //"red-5": #e02e40,
  //"red-6": #f9423a,
  //"red-7": #f17373,
  //"red-8": #f68e8f,
  //"red-9": #faa9ab,
  //"red-10": #fbc3c8,
  //"orange-0": #6f2601,
  //"orange-1": #862e03,
  //"orange-2": #b73f04,
  //"orange-3": #ff9351,
  //"orange-4": #ea5003,
  //"orange-5": #fe6018,
  //"orange-6": #ff7e45,
  //"orange-7": #ff9969,
  //"orange-8": #ffb18b,
  //"orange-9": #ffc7ac,
  //"orange-10": #feddcd,
  //"yellow-0": #806800,
  //"yellow-1": #8e7300,
  //"yellow-2": #aa8a00,
  //"yellow-3": #b89600,
  //"yellow-4": #c7a200,
  //"yellow-5": #d6ae00,
  //"yellow-6": #e5ba00,
  //"yellow-7": #f4c700,
  //"yellow-8": #ffb81c,
  //"yellow-9": #ffe483,
  //"yellow-10": #fff3bf
);

@each $name, $hex in $colours {
  .#{$name} {
    color: $hex;
    &:visited {
      color: $hex;
    }
  }
  .bg-#{$name} {
    background-color: $hex;
  }
  .b--#{$name} {
    border-color: $hex;
  }
  .hover-bg-#{$name} {
    &:focus,
    &:active,
    &:hover {
      background-color: $hex;
    }
  }
  .hover-#{$name}:hover,
  .hover-#{$name}:visited:hover {
    color: $hex;
  }
}

@media #{$breakpoint-small} {  
  @each $name, $hex in $colours {
    .#{$name}-s {
      color: $hex;
      &:visited {
        color: $hex;
      }
    }
    .bg-#{$name}-s {
      background-color: $hex;
    }
    .b--#{$name}-s {
      border-color: $hex;
    }
    .hover-bg-#{$name}-s {
      &:focus,
      &:active,
      &:hover {
        background-color: $hex;
      }
    }
    .hover-#{$name}-s:hover,
    .hover-#{$name}-s:visited:hover {
      color: $hex;
    }
  }
}

@media #{$breakpoint-medium} {  
  @each $name, $hex in $colours {
    .#{$name}-m {
      color: $hex;
      &:visited {
        color: $hex;
      }
    }
    .bg-#{$name}-m {
      background-color: $hex;
    }
    .b--#{$name}-m {
      border-color: $hex;
    }
    .hover-bg-#{$name}-m {
      &:focus,
      &:active,
      &:hover {
        background-color: $hex;
      }
    }
    .hover-#{$name}-m:hover,
    .hover-#{$name}-m:visited:hover {
      color: $hex;
    }
  }
}

@media #{$breakpoint-large} {  
  @each $name, $hex in $colours {
    .#{$name}-l {
      color: $hex;
      &:visited {
        color: $hex;
      }
    }
    .bg-#{$name}-l {
      background-color: $hex;
    }
    .b--#{$name}-l {
      border-color: $hex;
    }
    .hover-bg-#{$name}-l {
      &:focus,
      &:active,
      &:hover {
        background-color: $hex;
      }
    }
    .hover-#{$name}-l:hover,
    .hover-#{$name}-l:visited:hover {
      color: $hex;
    }
  }
}
