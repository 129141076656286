
// buttons

.btn {
  cursor: pointer;
  padding: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  &:hover {
    background: white;
    color: black;
    border: 1px solid white;
    font-family: $font3;
  }
}

.prev-arrow {
  position: relative;
  margin-left: 40px;
  @media #{$breakpoint-small-only} {
    font-size: 1.2rem;
  }
  &:before {
    content: "";
    background-image: url(../img/arrow.svg);
    background-size: 100%;
    height: 20px;
    width: 21px;
    position: absolute;
    transform: rotate(180deg);
    left: -40px;
    top: 0px;
  }
  a {
    &:hover {
      font-family: $font3;
    }
  }
}

.next-arrow {
  position: relative;
  margin-right: 40px;
  @media #{$breakpoint-small-only} {
    font-size: 1.2rem;
  }
  &:before {
    content: "";
    background-image: url(../img/arrow.svg);
    background-size: 100%;
    height: 20px;
    width: 21px;
    position: absolute;
    right: -40px;
    top: 0px;
  }
  a {
    &:hover {
      font-family: $font3;
    }
  }
}

.social-links {
  li {
    position: relative;
    &:first-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: "|";
      position: absolute;
      top: 1px;
      left: 9px;
    }
  }
}

.site-info {
  li {
    position: relative;
    &:nth-child(n + 2) {
      &:after {
        @media #{$breakpoint-large} {
          content: "|";
          position: absolute;
          top: 0px;
          left: -11px;
        }
      }
    }
  }
}

.menu-button {
  @media #{$breakpoint-small-only} {
    position: absolute;
    top: 21px;
    left: 10px;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 13px;
      width: 23px;
      background-image: url(../img/mobile-burger.svg);
    }
  }
}

.home {
  .menu-button {
    &:after {
      background-image: url(../img/mobile-burger-white.svg);
    }
  }
}

.menu-active + a {
  .menu-button {
    &:after {
      background-image: url(../img/mobile-burger-white.svg);
    }
  }
}

.menu-active {
  @media #{$breakpoint-small-only} {
    display: flex;
    background: black;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    flex-direction: column-reverse;
    &:after {
      background-image: url(../img/mobile-burger-white.svg);
    }
  }

  .menu-button {
    @media #{$breakpoint-small-only} {
      position: absolute;
      top: 21px;
      left: 20px;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 13px;
        width: 23px;
        background-image: url(../img/menu-close.svg);
      }
    }
  }
}

.nav-white + a {
  span {
    &:after {
      background-image: url(../img/mobile-burger-white.svg);
    }
  }
}

.menu-active + a {
  span {
    &:after {
      background-image: url(../img/menu-x.svg) !important;
      background-repeat: no-repeat;
      height: 19px;
      left: 2px;
      top: -2px;
    }
  }
}