// fade in animation

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in 1 forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fade-in-gallery {
  opacity: 0;
  animation: fadeIn 0.5s ease-in 1 forwards;
}