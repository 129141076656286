
// img.lazyload:not([src]) {
// 	visibility: hidden;
// }

// img.lazyload {
//   opacity: 0;
// }

// img:not(.initial) {
//   transition: opacity 1s;
// }

// img.initial,
// img.lazyloaded,
// img.error {
//   opacity: 1;
// }

// img:not([src]) {
//   visibility: hidden;
// }

// img.lazyload:not([src]) {
// 	visibility: hidden;
// }

// .lazyload,
// .lazyloading {
// 	opacity: 0; 
// }

// .loading,
// .lazyload,
// .lazyloaded,
// .image {
//   opacity: 1;
// 	transition: opacity 1000ms;
// }

img.lazyload {
	opacity: 0;
}
img:not(.initial) {
	transition: opacity 1s;
}
img.initial,
img.loaded,
img.error {
	opacity: 1;
}
// img:not([src]) {
// 	visibility: hidden;
// }