// Converted Variables

// Custom Media Query Variables

/*

   DISPLAY
   Docs: http://tachyons.io/docs/layout/display

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    tr    = table-row
    tcol  = table-column
    tcolg = table-column-group

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

$displays: (
  "di": inline,
  "din": inherit,
  "db": block,
  "dib": inline-block,
  "dit": inline-table,
  "dt": table,
  "dtc": table-cell,
  "dt-row": table-row,
  "dt-row-group": table-row-group,
  "dt-column": table-column,
  "dt-column-group": table-column-group,
  "flex": flex,
  "inline-flex": inline-flex,
  "dn": none
);

@each $name, $display in $displays {
  .#{$name} {
    display: $display;
  }
}

@each $name, $display in $displays {
  @media #{$breakpoint-small} {
    .#{$name}-s {
      display: $display;
    }
  }
}

@each $name, $display in $displays {
  @media #{$breakpoint-medium} {
    .#{$name}-m {
      display: $display;
    }
  }
}

@each $name, $display in $displays {
  @media #{$breakpoint-large} {
    .#{$name}-l {
      display: $display;
    }
  }
}
