// Converted Variables

// Custom Media Query Variables

/*

   MAX WIDTHS
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     mw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

$mWidths: (
  "mw-40": 40%,
  "mw-65": 65%,
  "mw-70": 70%,
  "mw-80": 80%,
  "mw-85": 85%,
  "mw-100": 100%,
  "mw1": $max-width-1,
  "mw2": $max-width-2,
  "mw3": $max-width-3,
  "mw4": $max-width-4,
  "mw5": $max-width-5,
  "mw6": $max-width-6,
  "mw7": $max-width-7,
  "mw8": $max-width-8,
  "mw9": $max-width-9,
  "mw-none": none
);

@each $name, $mWidth in $mWidths {
  .#{$name} {
    max-width: $mWidth;
  }
}

@media #{$breakpoint-small} {
  @each $name, $mWidth in $mWidths {
    .#{$name}-s {
      max-width: $mWidth;
    }
  }
}

@media #{$breakpoint-medium} {
  @each $name, $mWidth in $mWidths {
    .#{$name}-m {
      max-width: $mWidth;
    }
  }
}

@media #{$breakpoint-medium-landscape} {
  @each $name, $mWidth in $mWidths {
    .#{$name}-ml {
      max-width: $mWidth;
    }
  }
}

@media #{$breakpoint-large} {
  @each $name, $mWidth in $mWidths {
    .#{$name}-l {
      max-width: $mWidth;
    }
  }
}

// pixel widths

$maxWidths: (
  "100": 100px,
  "150": 150px, 
  "200": 200px,
  "250": 250px,
  "300": 300px,
  "320": 320px,
  "350": 350px,
  "380": 380px,
  "400": 400px,
  "440": 440px,
  "450": 450px,
  "500": 500px,
  "550": 550px,
  "600": 600px,
  "650": 650px,
  "700": 700px,
  "750": 750px,
  "800": 800px,
  "850": 850px,
  "900": 900px,
  "1000": 1000px,
  "1100": 1100px,
  "1200": 1200px,
  "1300": 1300px,
  "1400": 1400px,
  "1500": 1500px,
  "1600": 1600px,
  "1700": 1700px,
  "1800": 1800px
);

@each $name, $maxWidth in $maxWidths {
  .mw-#{$name} {
    max-width: $maxWidth;
  }
}

@media #{$breakpoint-small} {
  @each $name, $maxWidth in $maxWidths {
    .mw-#{$name}-s {
      max-width: $maxWidth;
    }
  }
}
 
@media #{$breakpoint-small-landscape} {
  @each $name, $maxWidth in $maxWidths {
    .mw-#{$name}-sl {
      max-width: $maxWidth;
    }
  }
}

@media #{$breakpoint-medium} {
  @each $name, $maxWidth in $maxWidths {
    .mw-#{$name}-m {
      max-width: $maxWidth;
    }
  }
}

@media #{$breakpoint-medium-landscape} {
  @each $name, $maxWidth in $maxWidths {
    .mw-#{$name}-ml {
      max-width: $maxWidth;
    }
  }
}

@media #{$breakpoint-large} {
  @each $name, $maxWidth in $maxWidths {
    .mw-#{$name}-l {
      max-width: $maxWidth;
    }
  }
}
