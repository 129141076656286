// Converted Variables

// Custom Media Query Variables

/*

  Z-INDEX

  Base
    z = z-index

  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
    -4 = literal value 4
    -5 = literal value 5
    -999 = literal value 999
    -9999 = literal value 9999

    -max = largest accepted z-index value as integer

    -inherit = string value inherit
    -initial = string value initial
    -unset = string value unset

  MDN: https://developer.mozilla.org/en/docs/Web/CSS/z-index
  Spec: http://www.w3.org/TR/CSS2/zindex.html
  Articles:
    https://philipwalton.com/articles/what-no-one-told-you-about-z-index/

  Tips on extending:
  There might be a time worth using negative z-index values.
  Or if you are using tachyons with another project, you might need to
  adjust these values to suit your needs.

*/ 
 
$zindexes: (
  "z-m1": -1,
  "z-0": 0,
  "z-1": 1,
  "z-2": 2,
  "z-3": 3,
  "z-4": 4,
  "z-5": 5,
  "z-20": 20,
  "z-999": 999,
  "z-1001": 1001,
  "z-9999": 9999,
  "z-max": 2147483647,
  "z-inherit": inherit,
  "z-initial": initial,
  "z-unset": unset
);
  
@each $name, $zindex in $zindexes {
  .#{$name} {
    z-index: $zindex;
  }
}

@media #{$breakpoint-small} {  
  @each $name, $zindex in $zindexes {
    .#{$name}-s {
      z-index: $zindex;
    }
  }
}

@media #{$breakpoint-medium} {  
  @each $name, $zindex in $zindexes {
    .#{$name}-m {
      z-index: $zindex;
    }
  }
}

@media #{$breakpoint-large} {  
  @each $name, $zindex in $zindexes {
    .#{$name}-l {
      z-index: $zindex;
    }
  }
}