.js-about-tab-switcher-content--active {
  display: flex;
}

.js-show-about-detail {
  span {
    display: flex;
  }

  p {
    margin-bottom: 2rem;
  }
}


#js-about-slider-list {
  display: flex;
  li {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1rem;
    margin-right: 1rem;
    float: right;
    right: 0;
    list-style: none;
  }
}

#js-about-slider-list li.current-slide {
  background: grey;
}
 
// nav stuff

#js-close-tab {
  background-image: url(../img/menu-close.svg);
  height: 17px;
  width: 17px;
  position: absolute;
  float: right;
  right: 5%;
  top: 10%;
  z-index: 3;
  @media only screen and (min-width:375px)  { 
    top: 100px;
  }
  @media #{$breakpoint-medium-landscape} { 
    top: 100px;
   }
}

#js-slide-popup > .row {
  display: none;
}
