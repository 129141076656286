// Converted Variables

$sans-serif: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto,
  "segoe ui", arial, sans-serif !default;
$serif: georgia, serif !default;
$code: consolas, monaco, monospace !default;
$font-size-headline: 6rem !default;
$font-size-subheadline: 5rem !default;

$font-size-0: 8rem !default;
$font-size-1: 6rem !default;
$font-size-2: 2rem !default;
$font-size-3: 1.6rem !default;
$font-size-4: 1.5rem !default;
$font-size-5: 1.3rem !default;
$font-size-6: 1.6rem !default;
$font-size-7: 1.4rem !default;
$font-size-8: 1.8rem !default;
$font-size-9: 1.6rem !default;
$font-size-10: 1.4rem !default;
$font-size-11: 1.2rem !default;
  
$letter-spacing-tight: -1px !default;
$letter-spacing-1: 1px !default;
$letter-spacing-2: 2px !default;
$line-height-solid: 1 !default;
$line-height-title: 1.2 !default;
$line-height-copy: 1.4 !default;
  
$measure: 30em !default;
$measure-narrow: 20em !default;
$measure-wide: 34em !default;

$spacing-none: 0 !default;
$spacing-extra-small: 0.25rem !default;
$spacing-small: 0.5rem !default;
$spacing-medium: 1rem !default;
$spacing-large: 2rem !default;
$spacing-extra-large: 4rem !default;
$spacing-extra-extra-large: 8rem !default;
$spacing-extra-extra-extra-large: 16rem !default;
$spacing-copy-separator: 1.5em !default;

$height-1: 1rem !default;
$height-2: 2rem !default;
$height-3: 4rem !default;
$height-4: 8rem !default;
$height-5: 16rem !default;
$height-6: 24rem !default;

$width-1: 1rem !default;
$width-2: 2rem !default;
$width-3: 4rem !default;
$width-4: 8rem !default;
$width-5: 16rem !default;
$width-6: 32rem !default;

$max-width-1: 1rem !default;
$max-width-2: 2rem !default;
$max-width-3: 4rem !default;
$max-width-4: 8rem !default;
$max-width-5: 16rem !default;
$max-width-6: 32rem !default;
$max-width-7: 48rem !default;
$max-width-8: 64rem !default;
$max-width-9: 96rem !default;

$min-width-1: 1rem !default;
$min-width-2: 2rem !default;
$min-width-3: 4rem !default;
$min-width-4: 8rem !default;
$min-width-5: 16rem !default;
$min-width-6: 32rem !default;
$min-width-7: 48rem !default;
$min-width-8: 64rem !default;
$min-width-9: 96rem !default;

$border-radius-none: 0 !default;
$border-radius-1: 0.125rem !default;
$border-radius-2: 0.25rem !default;
$border-radius-3: 0.5rem !default;
$border-radius-4: 1rem !default;
$border-radius-circle: 100% !default;
$border-radius-pill: 9999px !default;
$border-width-none: 0 !default;
$border-width-1: 0.125rem !default;
$border-width-2: 0.25rem !default;
$border-width-3: 0.5rem !default;
$border-width-4: 1rem !default;
$border-width-5: 2rem !default;

$box-shadow-1: 0px 0px 4px 2px rgba(0, 0, 0, 0.2) !default;
$box-shadow-2: 0px 0px 8px 2px rgba(0, 0, 0, 0.2) !default;
$box-shadow-3: 2px 2px 4px 2px rgba(0, 0, 0, 0.2) !default;
$box-shadow-4: 2px 2px 8px 0px rgba(0, 0, 0, 0.2) !default;
$box-shadow-5: 4px 4px 8px 0px rgba(0, 0, 0, 0.2) !default;

$black: #000 !default;
// $near-black: #111 !default;
// $dark-gray: #333 !default;
// $mid-gray: #555 !default;
// $gray: #777 !default;
// $silver: #999 !default;
// $light-silver: #aaa !default;
// $moon-gray: #ccc !default;
// $light-gray: #eee !default;
// $near-white: #f4f4f4 !default;
$white: #fff !default;
$transparent: transparent !default;

$black-90: rgba(0, 0, 0, 0.9) !default;
$black-80: rgba(0, 0, 0, 0.8) !default;
$black-70: rgba(0, 0, 0, 0.7) !default;
$black-60: rgba(0, 0, 0, 0.6) !default;
$black-50: rgba(0, 0, 0, 0.5) !default;
$black-40: rgba(0, 0, 0, 0.4) !default;
$black-30: rgba(0, 0, 0, 0.3) !default;
$black-20: rgba(0, 0, 0, 0.2) !default;
$black-10: rgba(0, 0, 0, 0.1) !default;
$black-05: rgba(0, 0, 0, 0.05) !default;
$black-025: rgba(0, 0, 0, 0.025) !default;
$black-0125: rgba(0, 0, 0, 0.0125) !default;

$white-90: rgba(255, 255, 255, 0.9) !default;
$white-80: rgba(255, 255, 255, 0.8) !default;
$white-70: rgba(255, 255, 255, 0.7) !default;
$white-60: rgba(255, 255, 255, 0.6) !default;
$white-50: rgba(255, 255, 255, 0.5) !default;
$white-40: rgba(255, 255, 255, 0.4) !default;
$white-30: rgba(255, 255, 255, 0.3) !default;
$white-20: rgba(255, 255, 255, 0.2) !default;
$white-10: rgba(255, 255, 255, 0.1) !default;
$white-05: rgba(255, 255, 255, 0.05) !default;
$white-025: rgba(255, 255, 255, 0.025) !default;
$white-0125: rgba(255, 255, 255, 0.0125) !default;
 
// $dark-red: #e7040f !default;
$red: #ff4136 !default;
// $light-red: #ff725c !default;
// $orange: #ff6300 !default;
// $gold: #ffb700 !default;
// $yellow: #ffd700 !default;
// $light-yellow: #fbf1a9 !default;
// $purple: #5e2ca5 !default;
// $light-purple: #a463f2 !default;
// $dark-pink: #d5008f !default;
// $hot-pink: #ff41b4 !default;
// $pink: #ff80cc !default;
// $light-pink: #ffa3d7 !default;
// $dark-green: #137752 !default;
// $green: #19a974 !default;
// $light-green: #9eebcf !default;
// $navy: #001b44 !default;
// $dark-blue: #00449e !default;
// $blue: #357edd !default;
// $light-blue: #96ccff !default;
// $lightest-blue: #cdecff !default;
// $washed-blue: #f6fffe !default;
// $washed-green: #e8fdf5 !default;
// $washed-yellow: #fffceb !default;
// $washed-red: #ffdfdf !default;

// Custom Media Query Variables

$breakpoint-small: "screen and (min-width: 376px)" !default;
$breakpoint-small-only: "screen and (max-width: 678px)" !default;

$breakpoint-medium: "screen and (min-width: 768px)" !default;
$breakpoint-medium-only: "screen and (min-width: 768px) and (max-width: 1025px)" !default;


$breakpoint-large: "screen and (min-width: 1025px)" !default;
$breakpoint-small-desktop: "screen and (min-width: 1025px) and (max-width: 1330px)" !default;
$breakpoint-verylarge: "screen and (min-width: 1600px)" !default;

// mobile and tablet landscape overwrite
// these stand alone and don't affect anything above or below
$breakpoint-small-landscape: "screen  and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape)";
$breakpoint-medium-landscape: "screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)";

/*
  VARIABLES
*/
