
nav {
  @media #{$breakpoint-small-only} {
    a {
      color: white;
    }
  }

  .active {
    font-family: MessinaBold, Arial, sans-serif;
  }
}