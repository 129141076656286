// Converted Variables

// Custom Media Query Variables

/*

   WIDTHS
   Docs: http://tachyons.io/docs/layout/widths/

   Base:
     w = width

     Modifiers
       1 = 1st step in width scale
       2 = 2nd step in width scale
       3 = 3rd step in width scale
       4 = 4th step in width scale
       5 = 5th step in width scale

       -10  = literal value 10%
       -20  = literal value 20%
       -25  = literal value 25%
       -30  = literal value 30%
       -33  = literal value 33%
       -34  = literal value 34%
       -40  = literal value 40%
       -50  = literal value 50%
       -60  = literal value 60%
       -70  = literal value 70%
       -75  = literal value 75%
       -80  = literal value 80%
       -90  = literal value 90%
       -100 = literal value 100%

       -third      = 100% / 3 (Not supported in opera mini or IE8)
       -two-thirds = 100% / 1.5 (Not supported in opera mini or IE8)
       -auto       = string value auto


     Media Query Extensions:
       -s = small
       -m  = medium
       -l  = large

  */

/* Width Scale */

$widths: (
  "0": 0%,
  "10": 10%,
  "15": 15%,
  "20": 20%,
  "25": 25%,
  "30": 30%,
  "31": 31.6%,
  "33": 33.3333333%,
  "35": 35%,
  "40": 40%,
  "48": 48%,
  "50": 50%,
  "60": 60%,
  "65": 65.76%,
  "66": 66.666666%,
  "70": 70%,
  "75": 75%,
  "80": 80%,
  "90": 90%,
  "100": 100%,
  "10px": 10px,
  "20px": 20px,
  "30px": 30px,
  "40px": 40px,
  "50px": 50px,
  "60px": 60px,
  "70px": 70px,
  "80px": 80px,
  "90px": 90px,
  "100px": 100px,
  "200px": 200px,
  "250px": 250px,
  "400px": 400px,
  "500": 500px,
  "650": 650px,
  "auto": auto,
  "inherit": inherit,
  "third": (
    100% / 3
  ),
  "two-thirds": (
    100% / 1.5
  )
);

$widthRems: (
  "1": $width-1,
  "2": $width-2,
  "3": $width-3,
  "4": $width-4,
  "5": $width-5,
  "6": $width-6,
  "10": 10rem,
  "11": 11rem,
  "12": 12rem,
  "13": 13rem,
  "14": 14rem,
  "15": 15rem,
  "16": 16rem,
  "17": 17rem,
  "18": 18rem,
  "19": 19rem,
  "20": 20rem
);

@each $name, $width in $widths {
  .w-#{$name} {
    width: $width;
  }
}

@each $name, $width in $widthRems {
  .w#{$name} {
    width: $width;
  }
}

// SMALL BREAKPOINT

@media #{$breakpoint-small} {
  @each $name, $width in $widths {
    .w-#{$name}-s {
      width: $width;
    }
  }
  @each $name, $width in $widthRems {
    .w#{$name}-s {
      width: $width;
    }
  }
}

// MEDIUM BREAKPOINT

@media #{$breakpoint-medium} {
  @each $name, $width in $widths {
    .w-#{$name}-m {
      width: $width;
    }
  }
  @each $name, $width in $widthRems {
    .w#{$name}-m {
      width: $width;
    }
  }
}

@media #{$breakpoint-medium-landscape} {
  @each $name, $width in $widths {
    .w-#{$name}-ml {
      width: $width;
    }
  }
  @each $name, $width in $widthRems {
    .w#{$name}-ml {
      width: $width;
    }
  }
}

// DESKTOP SMALL BREAKPOINT

@media #{$breakpoint-small-desktop} {
  @each $name, $width in $widths {
    .w-#{$name}-sd {
      width: $width;
    }
  }
}

// LARGE BREAKPOINT

@media #{$breakpoint-large} {
  @each $name, $width in $widths {
    .w-#{$name}-l {
      width: $width;
    }
  }
  @each $name, $width in $widthRems {
    .w#{$name}-l {
      width: $width;
    }
  }
}
