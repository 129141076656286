// text underline

@mixin after-underline() {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -5px;
    border-width: 0 0 1px;
    border-style: solid;
  }

  &:hover {
    font-family: MessinaBold, Arial, sans-serif;
  }
}

@mixin after-underline-two() {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 2px;
    border-width: 0 0 1px;
    border-style: solid;
  }

  &:hover {
    font-family: MessinaBold, Arial, sans-serif;
  }
}
 
.after-underline {
  @include after-underline();
}
 
// text decoration

.after-underline {
  position: relative;
  &:hover {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 1px;
      border-width: 0 0 1px;
      border-style: solid;
    }
  }
}
