// Converted Variables

// Custom Media Query Variables

/*

   LINE HEIGHT / LEADING
   Docs: http://tachyons.io/docs/typography/line-height

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.lh-solid {
  line-height: $line-height-solid;
}
.lh-title {
  line-height: $line-height-title;
}
.lh-copy {
  line-height: $line-height-copy;
}
.lh-large {
  line-height: 1.7;
}

@media #{$breakpoint-small} {
  .lh-solid-s {
    line-height: $line-height-solid;
  }
  .lh-title-s {
    line-height: $line-height-title;
  }
  .lh-copy-s {
    line-height: $line-height-copy;
  }
}

@media #{$breakpoint-medium} {
  .lh-solid-m {
    line-height: $line-height-solid;
  }
  .lh-title-m {
    line-height: $line-height-title;
  }
  .lh-copy-m {
    line-height: $line-height-copy;
  }
}

@media #{$breakpoint-large} {
  .lh-solid-l {
    line-height: $line-height-solid;
  }
  .lh-title-l {
    line-height: $line-height-title;
  }
  .lh-copy-l {
    line-height: $line-height-copy;
  }
}
