/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}


// project font(s)

@font-face {
  font-family: MessinaRegular;
  src: url(../fonts/MessinaSansMonoTrialWeb-Regular.woff);
}

@font-face {
  font-family: MessinaBook;
  src: url(../fonts/MessinaSansMonoTrialWeb-Book.woff);
}

@font-face {
  font-family: MessinaBold;
  src: url(../fonts/MessinaSansMonoTrialWeb-SemiBold.woff);
}

$font: MessinaRegular, Arial, sans-serif;
$font2: MessinaBook, Arial, sans-serif;
$font3: MessinaBold, Arial, sans-serif;
$fontColour: black;

$MessinaBook: MessinaBook, Arial, sans-serif;

.fn1 {
  font-family: $font;
}

.fn2 {
  font-family: $font2;
}

.fn3 {
  font-family: $font3;
}

.f4 {
  font-size: 1.5rem;
}

.MessinaBook {
  @font-face {
    font-family: MessinaBook;
    src: url(../fonts/MessinaSansMonoTrialWeb-Book.woff);
  }
}
 
body {
  font-family: $MessinaBook;
}

p {
  font-family: $MessinaBook;
}

p + p {
  margin-bottom: 1.5rem;
}

.allp {
  p {
    margin-bottom: 1.5rem;
  }
}

/* Default Link Styles */
a {
  // color: $primaryColour;
  line-height: inherit;
  text-decoration: none;

  img {
    border: none;
  }
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

/* Unordered Lists */
ul {
  li {
    ul,
    ol {
      margin-bottom: 0;
    }
  }

  &.square,
  &.circle,
  &.disc {
    li ul {
      list-style: inherit;
    }
  }
}

/* Ordered Lists */
ol {
  // margin-#{$default-float}: $list-ordered-side-margin;
  li {
    ul,
    ol {
      // margin-#{$default-float}: $list-nested-margin;
      margin-bottom: 0;
    }
  }
}

/* Abbreviations */
abbr,
acronym {
  // color: $body-font-color;
  font-size: 90%;
  text-transform: uppercase;
}

////////////////////////////////////////////////////////////// project typography

/* ============================================================
basic type
============================================================  */

$paragraph-line-height: 1;
$paragraph-font-size: 2rem;
$paragraph-font-family: $font;
$paragraph-font-weight: 300;

$header-font-family: $font;
// $header-font-color: $primaryColour;
$header-line-height: 1.1;

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
a,
button,
ul,
ol,
dl li,
input,
form,
div,
span,
section {
  font-family: $MessinaBook;
  font-style: normal;
  font-weight: normal;
  color: black;
}

ul li ul,
ul li ol,
ul {
  margin-bottom: 0;
  margin-left: 0rem;
}

