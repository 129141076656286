//

#js-bgImageSlider {
  height: 100vh;
  width: 100%;
  @media #{$breakpoint-small-only} { 
    height: 85vh;
    display: flex;
    justify-content: center;
  }
  span.slideShowBg {
    height: 100%;
    width: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    transition: opacity 2s ease-in;
  }
  span.slideShowBg + span.slideShowBg {
    opacity: 0;
  }
  img {
    display: none;
  }
}
