.slide-arrow {
  &:after {
    content: "";
    background: url("../img/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100%;
    height: 27px;
    width: 20px;
     
    @media only screen and (min-width: 1024px) {
      bottom: 35px;
      display: block;
    }
    
    @media only screen and (max-width: 667px)  { 
      display: none;
    }

  }
}

.about-active {
  position: relative;
  @media only screen and (min-width: 1025px) {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -5px;
      border-width: 0 0 1px;
      border-style: solid;
    }
    &:hover {
      &:after {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-width: 0 0 1px;
        border-style: solid;
      }
    }
  }
}