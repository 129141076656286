// Converted Variables

// Custom Media Query Variables

/*

   POSITIONING
   Docs: http://tachyons.io/docs/layout/position/

   Media Query Extensions:
     -s = small
     -m  = medium
     -l  = large

*/

.static {
  position: static;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

@media #{$breakpoint-small} {
  .static-s {
    position: static;
  }
  .relative-s {
    position: relative;
  }
  .absolute-s {
    position: absolute;
  }
  .fixed-s {
    position: fixed;
  }
}

@media #{$breakpoint-medium} {
  .static-m {
    position: static;
  }
  .relative-m {
    position: relative;
  }
  .absolute-m {
    position: absolute;
  }
  .fixed-m {
    position: fixed;
  }
}

@media #{$breakpoint-large} {
  .static-l {
    position: static;
  }
  .relative-l {
    position: relative;
  }
  .absolute-l {
    position: absolute;
  }
  .fixed-l {
    position: fixed;
  }
}
